/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { DefaultButton, Stack } from "office-ui-fabric-react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import {
  validateAbhyasiId,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/validations";
import { noop } from "../../utils/lodash";

const getEMI = (value) => {
  if (!value) return "abhyasiid";

  if (validateAbhyasiId(value.toUpperCase())) {
    return "abhyasiid";
  }
  if (validatePhoneNumber(value)) {
    return "mobile";
  }
  if (validateEmail(value.toLowerCase())) {
    return "email";
  }
  return "abhyasiid";
};

function AbhyasiField({ onChange, searchText, disabled, initialVal }) {
  const [emi, setEmi] = useState(getEMI(initialVal));
  const [value, setValue] = useState(initialVal);
  const [valid, setValid] = useState(false);

  const onSearch = useCallback(() => {
    if (value) {
      if (validateAbhyasiId(value.toUpperCase())) {
        onChange(value.toUpperCase(), "ref");
      } else if (validatePhoneNumber(value)) {
        onChange(value, "mobile");
      } else if (validateEmail(value.toLowerCase())) {
        onChange(value.toLowerCase(), "email");
      }
    }
  }, [value, onChange]);

  const updateValue = useCallback(
    (v) => {
      if (emi === "email") {
        setValue(v.toLowerCase());
        if (validateEmail(v)) {
          setValid(true);
          return;
        }
      } else if (emi === "abhyasiid") {
        setValue(v.toUpperCase());
        if (validateAbhyasiId(v)) {
          setValid(true);
          return;
        }
      } else if (emi === "phone") {
        setValue(`+${v.replace(/\D/g, "")}`);
        if (validatePhoneNumber(v) && v[0] === "+" && v.length > 11) {
          setValid(true);
          return;
        }
      }
      setValid(false);
    },
    [setValid, setValue, emi]
  );

  useEffect(() => {
    if (getEMI(initialVal) === emi) {
      updateValue(initialVal);
    } else {
      setValue();
    }
  }, [emi, setValue, updateValue, initialVal]);

  return (
    <Stack horizontalAlign="start">
      <Stack horizontal>
        <DynamicField
          type="select"
          name="emi"
          label=""
          value={emi}
          onChange={setEmi}
          options={[
            { name: "email", label: "Email" },
            { name: "phone", label: "Mobile" },
            { name: "abhyasiid", label: "Heartfulness ID" },
          ]}
          props={{ disabled }}
        />
        <DynamicField
          props={{ autoFocus: true, disabled }}
          name="value"
          value={value}
          label=""
          onChange={updateValue}
          type={emi}
        />
        <DefaultButton
          disabled={!valid || disabled}
          iconProps={{ iconName: "Search" }}
          text={searchText}
          onClick={onSearch}
        />
      </Stack>
      {emi === "phone" && (
        <Text variant="description">
          Please enter phone in format: +919900990099
        </Text>
      )}
    </Stack>
  );
}

AbhyasiField.propTypes = {
  onChange: PropTypes.func,
  searchText: PropTypes.string,
  disabled: PropTypes.bool,
  initialVal: PropTypes.string,
};
AbhyasiField.defaultProps = {
  onChange: noop,
  searchText: "Search",
  disabled: false,
  initialVal: "",
};

export default AbhyasiField;
