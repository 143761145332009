/** @jsx jsx */
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import { DefaultButton, Spinner, Stack } from "office-ui-fabric-react";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import AbhyasiField from "sites-common/components/AbhyasiField";
import { fetchSrcmAPI } from "sites-common/service-wrappers/profile-api";
import { jsx, Text } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";
import { validateEmail } from "sites-common/utils/validations";
import DLWLayout from "../../layouts/dlw-mobile";
import ProfileWidgetSubscriptions from "../../components/ProfileWidgetCore/ProfileWidgetSubscriptions";

const INITIAL = 0;
const LOADING = 1;
const WAITING_FOR_OTP = 2;
const VALIDATING = 3;
const SUCCESS = 4;

const OTP = () => {
  const [emailId] = useQueryParam("email", StringParam);

  const dispatch = useDispatch();
  const [step, setStep] = useState(INITIAL);
  const [key, setKey] = useState("");
  const [otp, setOtp] = useState("");

  const [resp, setResp] = useState("");
  const [sbt, setSbt] = useState(null);

  // const [editing, { setTrue: setEditing, setFalse: resetEditing }] =
  //   useMyBoolean(false);

  // const resetSteps = useCallback(() => {
  //   setStep(INITIAL);
  //   resetEditing();
  //   setSbt(null);
  //   setKey("");
  //   setOtp("");
  // }, [setStep, resetEditing, setSbt, setKey, setOtp]);

  const requestOtp = useCallback(
    (val, type) => {
      setStep(LOADING);
      fetchSrcmAPI("/api/v2/abhyasis/get-otp/", "", "POST", {}, {}, false, {
        [type]: val,
        send_mobile_otp: true,
        send_email_otp: true,
      })
        .then((response) => {
          setStep(WAITING_FOR_OTP);
          setKey(response.key);
        })
        .catch((e) => {
          setStep(INITIAL);
          dispatch(
            showAlert({
              title: `${
                type === "ref" ? "Heartfulness Id" : doFormatTitle(type)
              } ${val}`,
              message: `${e.toString()}`,
            })
          );
        });
    },
    [dispatch, setStep]
  );

  const validateOtp = useCallback(() => {
    setStep(VALIDATING);
    fetchSrcmAPI("/api/v2/abhyasis/verify-otp/", "", "POST", {}, {}, false, {
      key,
      otp,
    })
      .then((response) => {
        setStep(SUCCESS);
        setKey("");

        setSbt(response.deeplink_token);

        fetchSrcmAPI("/api/v2/me/", response.deeplink_token)
          .then((me) => setResp(me.results[0]))
          .catch((e) => {
            dispatch(
              showAlert({
                title: `Fetching Profile`,
                message: `${e.toString()}`,
              })
            );
          });
      })
      .catch((e) => {
        setStep(INITIAL);
        dispatch(
          showAlert({
            title: `Alert in OTP Validation`,
            message: `${e.toString()}`,
          })
        );
      });
  }, [dispatch, setStep, key, otp]);

  useEffect(() => {
    if (!!emailId && validateEmail(emailId)) {
      requestOtp(emailId, "email");
    }
  }, [requestOtp, emailId]);

  return (
    <DLWLayout>
      <Text variant="header"> Unsubscribe </Text>
      <Text sx={{ mt: 2, ml: 2, textAlign: "left" }}>
        By unsubscribing to the mailing list, you will no longer receive updates
        or promotional news from Heartfulness.
      </Text>

      <div sx={{ textAlign: "center" }}>
        {step !== SUCCESS && (
          <div sx={{ bg: "#dab894", p: 2, my: 3 }}>
            <Stack tokens={{ childrenGap: 20 }}>
              {step !== SUCCESS && (
                <div>
                  <div sx={{ mb: 2 }}>
                    <Text variant="header">Verification</Text>
                  </div>
                  {step !== INITIAL && validateEmail(emailId) ? (
                    <Text>
                      An OTP (one-time verification code) has been sent to{" "}
                      {emailId}
                    </Text>
                  ) : (
                    <AbhyasiField
                      onChange={requestOtp}
                      searchText="Get OTP"
                      disabled={step !== INITIAL}
                      initialVal={emailId}
                    />
                  )}
                </div>
              )}
              {(step === WAITING_FOR_OTP || step === VALIDATING) && (
                <Stack horizontal verticalAlign="end">
                  <DynamicField
                    name="otp"
                    label="Enter 6-digit OTP Received"
                    value={otp}
                    onChange={setOtp}
                  />
                  <DefaultButton
                    disabled={otp.length !== 6}
                    onClick={validateOtp}
                    text="Validate OTP"
                  />
                </Stack>
              )}
            </Stack>
            {(step === LOADING || step === VALIDATING) && (
              <Spinner sx={{ my: 2 }} />
            )}
          </div>
        )}
        {step === SUCCESS && (
          <div sx={{ bg: "#ffcaaf", p: 2, my: 3 }}>
            {!!resp && !!sbt ? (
              <div>
                <Text variant="header">
                  Subscriptions for{" "}
                  {resp.name || resp.user_email || resp.mobile}
                </Text>
                <ProfileWidgetSubscriptions meId={resp.id} authToken={sbt} />
              </div>
            ) : (
              <div sx={{ m: 4 }}>
                Verification Successful! Fetching Subscriptions ...
                <br />
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </DLWLayout>
  );
};
export default OTP;
